const appIdRegex = {
  alphanumeric: {
    characterClass: /^[0-9a-zA-Z]*$/,
    validClass: /^[0-9a-zA-Z]{14,16}$/,
    minAppIdLen: 14,
    maxAppIdLen: 16,
  },
  numeric: {
    characterClass: /^[0-9]*$/,
    validClass: /^[0-9]{14,17}$/,
    minAppIdLen: 14,
    maxAppIdLen: 17,
  },
};

const numberRegex = /^[0-9]*$/;

const textRegex = /^[a-zA-Z0-9.'#, ]*$/;

const alphaNumRegex = /^[a-zA-Z0-9]*$/;

const eidRegex = /^[a-zA-Z]{3}[0-9]{3}$/;

const routingRegex = /^[0-9]{0,9}$/;

const getAppIdType = () => {
  const paramsSearch = new URLSearchParams(document.location.search);
  const paramsAlphanumeric = paramsSearch.get('alphanumeric');

  if (!paramsAlphanumeric) {
    return process.env.REACT_APP_ID_TYPE || 'numeric';
  }

  return paramsAlphanumeric === 'true' ? 'alphanumeric' : 'numeric';
};

export { appIdRegex, numberRegex, textRegex, alphaNumRegex, eidRegex, routingRegex, getAppIdType };
