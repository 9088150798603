import React, { useState, useReducer } from 'react';
import { Grid } from '@cof/gravity-react';

import './Body.scss';

import SecuredCardService from '../../services/SecuredCardService';
import { logError, logMessage } from '../../utils/logging';
import { appIdRegex, numberRegex, getAppIdType } from '../../utils/validations';

import AgentForm from '../AgentForm';
import CustomerForm from '../CustomerForm';
import DepositAmountForm from '../DepositAmountForm';
import DepositHistoryDialog from '../DepositHistoryDialog';
import DepositAccountForm from '../DepositAccountForm';
import AccountOwnerInfoForm from '../AccountOwnerInfoForm';
import DepositSummary from '../DepositSummary/DepositSummary';
import ConfirmationPage from '../ConfirmationPage';
import FormContainer from '../FormContainer';
import TermsDialog from '../TermsDialog';
import TimedOut from '../TimedOut';

const depositSteps = [
  { key: 'agent', component: <AgentForm /> },
  { key: 'authorization', component: <CustomerForm /> },
  { key: 'deposit-amount', component: <DepositAmountForm /> },
  { key: 'bank-account-info', component: <DepositAccountForm /> },
  { key: 'bank-account-owner', component: <AccountOwnerInfoForm /> },
  { key: 'deposit-summary', component: <DepositSummary /> },
  { key: 'confirmation', component: <ConfirmationPage /> },
];

const Body = ({ activeLanguage, potomacLoaded, showTimedOutPage, showErrorDialog, showFaqDialog, showDepositCreditLineDialog, showRoutingAccountDialog, showSubmitDialog, showSpinner, hideSpinner }) => {
  const [activeKey, setActivePage] = useState(depositSteps[((document.location.pathname === '/agent') ? 0 : 1)].key);
  const [depositHistoryDialogActive, setDepositHistoryDialogActive] = useState(false);
  const [termsDialogActive, setTermsDialogActive] = useState(false);
  const [pageData, setPageData] = useReducer((pageData, response) => ({ ...pageData, ...response }), {});
  const [addressData, setAddressData] = useState({});
  const [maximumDepositAmount, setMaximumDepositAmount] = useState(1000.0);

  const advancePage = (data, fromNewSCApproval = false) => {
    let current = depositSteps.findIndex(step => step.key === activeKey);
    if (fromNewSCApproval) current += 1;
    if (current !== depositSteps.length - 1) {
      showSpinner();
      processData(data, current);
    }
  };

  const previousPage = (data) => {
    const current = depositSteps.findIndex(step => step.key === activeKey);
    if (current !== 0) {
      setPageData(data);
      setActivePage(depositSteps[current - 1].key);
    }
  };

  const validateDepositAmount = (depositAmount, maxDepositAmount, transactionHistory) => {
    let depositHistoryTotal = 0;
    transactionHistory['funding-transactions'].forEach(transaction => { depositHistoryTotal += transaction.transactionAmount; });
    const maxAllowableDeposit = maxDepositAmount - depositHistoryTotal;
    return depositAmount !== '' && depositAmount >= 20 && depositAmount <= maxAllowableDeposit;
  };

  // Used only for debugging NaN issue. This, as well as the logging, can be removed once debugging is complete.
  const getSanitizedFundingTransactions = (fundingTransactions) => fundingTransactions?.map((fundingTransaction) => ({
    ...fundingTransaction,
    // Nullify sensitive fields
    lastFourBAN: null,
    transactionReferenceNumber: null,
  }));

  const logDataProcess = (data, pageIndex) => {
    const applicationIdExisting = pageData?.srn;
    const applicationIdNew = data?.srn;
    const depositHistoryTotalExisting = pageData?.depositHistoryTotal;
    const depositHistoryTotalNew = data?.depositHistoryTotal;

    logMessage(
      'Deposit history total', {
        pageIndex,
        applicationIdExisting,
        applicationIdNew,
        depositHistoryTotalExisting,
        depositHistoryTotalNew,
      });
  };

  const getDepositTotalHistoryAmount = (fundingTransactions) => {
    const applicationId = pageData?.srn ?? 'Unknown ID';

    if (fundingTransactions.length === 0) {
      logMessage('getDepositTotalHistoryAmount', { message: `Application ${applicationId} does not have funding transactions` });
      return 0;
    }

    const total = fundingTransactions.reduce((amount, transaction) => {
      const transactionAmount = transaction?.transactionAmount;
      if (!transactionAmount) {
        logMessage('getDepositTotalHistoryAmount', { message: `Application ${applicationId} has a falsy value for transaction amount in funding history` });
        return amount;
      }

      return amount + transactionAmount;
    }, 0);

    logMessage('getDepositTotalHistoryAmount', { message: `Application ${applicationId} does have funding transactions`, depositHistory: total });
    return total;
  };

  const processData = async (data, currentIndex) => {
    if (currentIndex >= 2) {
      logDataProcess(data, currentIndex);
    }
    let errorMessage = null;
    switch (activeKey) {
    case 'agent':
      setPageData(data);
      if ('srn' in data) {
        currentIndex++;
      } else {
        break;
      }
      // eslint-disable-next-line no-fallthrough
    case 'authorization':
      {
        const feResponse = await SecuredCardService.authorize(data);
        if ('error' in feResponse) {
          errorMessage = feResponse.error === 404 ? 'invalidApplicant' : 'genericError';
          if(feResponse.error === 500 || feResponse.error === 401 || feResponse.error === 403) {
            const errorObject = {
              responseError: feResponse,
              requestData: data?.srn,
            };
            logError('Authorization error: ' + errorMessage, errorObject);
          }
        } else if (!feResponse.isValidApplicant) {
          errorMessage = 'invalidApplicant';
        } else if (feResponse.isCardActive) {
          errorMessage = 'cardActive';
        } else if (!feResponse.isEligibleToFund) {
          errorMessage = 'ineligibleToFund';
        } else {
          setPageData({ ...feResponse, ...data });
          setMaximumDepositAmount(feResponse.maximumDepositAmount);
          const historyResponse = await SecuredCardService.getDepositHistory(data);
          if('error' in historyResponse) {
            const errorObject = {
              responseError: historyResponse.error,
              requestData: data?.srn,
            };
            logError('DepositHistory error', errorObject);
          }
          const fundingTransactions = 'error' in historyResponse ? [] : historyResponse['funding-transactions'];

          setPageData({ 'funding-transactions': fundingTransactions });

          // Used only for debugging NaN issue. This, as well as the logging, can be removed once debugging is complete.
          const fundingTransactionsSanitized = getSanitizedFundingTransactions(fundingTransactions);
          const loggingMessage = {
            applicationId: data.srn,
            fundingTransactionsSanitized,
          };

          logMessage('get-funding-transaction response', { 'response': JSON.stringify(loggingMessage) });

          // The deposit history should be calculated outside of any specific component since it's possible
          // for users to bypass certain components, like the deposit amount component.
          const depositHistoryTotal = getDepositTotalHistoryAmount(fundingTransactions);
          setPageData({ depositHistoryTotal });

          logMessage('DepositHistoryTotal', { applicationId: data?.srn, depositHistoryTotal });

          if ('depositAmount' in data && !validateDepositAmount(data['depositAmount'], feResponse.maximumDepositAmount, historyResponse)) {
            currentIndex -= 1;
          }
        }
      }
      break;
    case 'deposit-amount':
      setPageData(data);
      break;
    case 'bank-account-info':
    {
      const badBankResponse = await SecuredCardService.validateBankAccount(data);
      if('error' in badBankResponse) {
        const errorObject = {
          responseError: badBankResponse.error,
          requestData: data?.srn,
        };
        logError('ValidateBankAccount error', errorObject);
      }
      badBankResponse.isBadBankAccount ? errorMessage = 'badBankAccount' : setPageData(data);
      break;
    }
    case 'bank-account-owner':
      setPageData(data);
      break;
    case 'deposit-summary':
    {
      const submitDepositResponse = await SecuredCardService.submitDeposit(pageData);
      if ('error' in submitDepositResponse) {
        const errorObject = {
          responseError: submitDepositResponse,
          requestData: pageData?.srn,
        };
        switch (submitDepositResponse.error) {
        case 400:
          errorMessage = 'submitBadRequest';
          break;
        case 404:
          errorMessage = 'submitInvalidSRN';
          break;
        case 412:
          errorMessage = 'submitDepositExceedsMax';
          break;
        case 424:
          errorMessage = 'submitApplicationFailed';
          break;
        default:
          errorMessage = 'submitConfirmationFailed';
          logError('SubmitDeposit error: ' + errorMessage, errorObject);
        }
      } else {
        setPageData(submitDepositResponse);
      }
      break;
    }
    default:
    }
    hideSpinner();
    if (errorMessage != null) {
      showErrorDialog(errorMessage, maximumDepositAmount);
    } else {
      setActivePage(depositSteps[currentIndex + 1].key);
    }
  };

  if (document.location.pathname === '/approved'
      && document.location.search.startsWith('?CustomerId=')
      && document.location.search.includes('SocialSecurityNumber')
      && document.location.search.includes('ZipCode')
      && document.location.search.includes('MinimumDepositRequired')) {
    const params = new URLSearchParams(document.location.search);
    const srn = params.get('CustomerId');
    const social = params.get('SocialSecurityNumber');
    const zipCode = params.get('ZipCode');
    const appIdType = getAppIdType();

    if (appIdRegex[appIdType].characterClass.test(srn)
          && appIdRegex[appIdType].validClass.test(srn)
          && numberRegex.test(social)
          && numberRegex.test(zipCode)
          && social.length === 4
          && zipCode.length === 5
          && (srn.length >= appIdRegex[appIdType].minAppIdLen && srn.length <= appIdRegex[appIdType].maxAppIdLen)
    ) {
      if (params.has('DepositAmount') && numberRegex.test(params.get('DepositAmount'))) {
        const depositAmount = params.get('DepositAmount');
        advancePage({ srn, social, zipCode, depositAmount, 'applicationEntryPoint': 'fromNewDAPP' }, true);
      } else {
        advancePage({ srn, social, zipCode, 'applicationEntryPoint': 'fromNewDAPP' });
      }
    }
    window.history.replaceState(null, null, '/');
  }

  const showDepositHistoryDialog = () => setDepositHistoryDialogActive(true);
  const showTermsDialog = (data) => { setAddressData(data); setTermsDialogActive(true); };

  const activeComponent = depositSteps.filter(step => step.key === activeKey)[0].component;
  const props = {
    activeLanguage,
    potomacLoaded,
    advancePage,
    previousPage,
    showFaqDialog,
    showDepositHistoryDialog,
    showDepositCreditLineDialog,
    showRoutingAccountDialog,
    showTermsDialog,
    showSubmitDialog,
    pageData,
  };
  const childWithProps = React.cloneElement(showTimedOutPage ? <TimedOut activeLanguage={activeLanguage} /> : activeComponent, props);

  return (
    <Grid className="form-body">
      <FormContainer>
        {childWithProps}
      </FormContainer>
      <DepositHistoryDialog
        activeLanguage={activeLanguage}
        depositHistory={pageData['funding-transactions']}
        active={depositHistoryDialogActive}
        onCancel={() => setDepositHistoryDialogActive(false)}
      />
      <TermsDialog
        activeLanguage={activeLanguage}
        potomacLoaded={potomacLoaded}
        active={termsDialogActive}
        onCancel={() => setTermsDialogActive(false)}
        nextPage={(data) => { advancePage(data); setTermsDialogActive(false); }}
        address={addressData}
        srn={pageData.srn}
      />
    </Grid>
  );
};

export default Body;
